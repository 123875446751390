.currencyInputContainer {
 display: inline;
 border: solid 1px #CAD5DB;
 padding: 8px;
 font-size: 16px;
 border-radius: 8px;
}

.currencyInputContainer:has(.currencyInput:disabled) {
    background-color: #F5FAFC;
}

.currencyInputContainer>.currencyInput {
    border: none;
    background: transparent;
    font-family: 'HarmoniaSansProCyr';
    font-size: 16px;
}