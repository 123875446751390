.gray-line-copyed {
  height: 100%;
  width: 10px;
  background: #98a2a8;
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s;
  z-index: 100;
}

.gray-line-copyed:hover {
  width: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-line-copyed span {
  display: none;
}

.gray-line-copyed:hover span {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #e8edee;
  letter-spacing: 0.05em;
}

.gray-line-copyed:hover span a {
  color: #e8edee;
  text-decoration: underline;
  cursor: pointer;
}
