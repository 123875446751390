.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.statusLoader_container {
  border-radius: 8px;
  width: 686px;
  height: 172px;
  padding: 20px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  position: relative;
  /* margin-left: auto;
  margin-right: auto; */
}

.loader {
  position: relative;
  align-content: center;
  align-self: center;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#ff3b30, transparent);

  animation: rotating 1s linear infinite;
}

.loader::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: white;
}

@keyframes rotating {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.statusLoader_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  align-content: center;
  align-self: center;
}
