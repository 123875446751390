.request-list-item-cost.advance {
  margin-top: -3px;
  font-size: 16px;
  font-weight: 400;
}

.request-list-item-cost.amount {
  font-size: 16px;
  margin-top: -7px;
}

span.request-list-item-status.advance {
  margin-top: 18px;
}

.request-list-item-right {
  max-width: 320px;
}

@media (max-width: 768px) {
  .request-list-item-right {
    min-width: 0;
    max-width: none;
  }
}

.gray-line-byExpense {
  /* height: 100%;
    width: 10px;
    background: #98a2a8;
    left: 0;
    top: 0; */
  position: absolute;
  transition: all 0.3s;
  z-index: 100;
}

.gray-line-byExpense:hover {
  width: 217px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}

.gray-line-byExpense p {
  display: none;
  color: #fff;
}

.gray-line-byExpense:hover p {
  display: block;
  font-size: 12px;
  line-height: 16px;
  /* color: #E8EDEE; */
  letter-spacing: 0.05em;
  text-transform: none;
}

.gray-line-byExpense:hover p a {
  color: #e8edee;
  text-decoration: underline;
  cursor: pointer;
}

.request-list-item-notice:hover span {
  left: 93%;
}
