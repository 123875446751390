.btn-expense.btn_gray.add-trip.daily {
  height: 36px;
  font-size: 16px;
  margin-left: 0px;
}

.btn.daily {
  height: 36px;
  font-size: 16px;
  width: auto;
  min-width: auto;
  margin-left: 0px;
}

span.daily-detail {
  text-decoration: underline;
}
