.profile-calc-table.table-sub .profile-calc-table-row > :last-child {
  max-width: 70px;
  min-width: 70px;
}

.profile-calc-table-td {
  word-wrap: break-word;
  /* display: block; */
  /* padding: 15px 5px; */
}

.grayRow {
  background: #e5e5e5 !important;
}

.grayRow:hover ~ .postings_alert_message {
  visibility: visible;
  opacity: 0.9;
}

.postings_alert_message {
  position: absolute;
  width: 300px;
  height: 45px;
  visibility: hidden;
  margin-top: -40px;
  margin-left: -10px;
  font-size: 12px;
  background: #555;
  opacity: 0;
  transition: all 0.3s;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
}

.postings_alert_message::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
