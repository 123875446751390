.assistanceRowContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  gap: 4px;
  border-radius: 4px;
}

.assistanceRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assistanceRowName {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

.assistanceWrapper {
  max-height: 170px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.assistanceWrapper::-webkit-scrollbar {
  display: none;
}

.assistanceRowCompany {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 1px;
}

.assistanceSearchRowContainer {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  padding: 12px;
  gap: 4px;
  border-radius: 4px;
}

.assistanceSearchRadioContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}
