.DayPicker-Caption {
  display: flex;
  justify-content: center;
  padding: 0 0.5em;
  text-align: center;
  margin-bottom: 25px;
}
.DayPicker-Day--outside {
  color: #d9e2e7;
  cursor: default;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled) .date-item-container {
  position: relative;
  background-color: #e31b23;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: white;
  color: white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  )
  .date-item-container:hover {
  background: #e31b23;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background: white;
}

.DayPicker-Day--period {
  color: black;
  background: rgba(249, 210, 211, 0.4);
  border-radius: 0;
}

.DayPicker-Day--startDate {
  background: linear-gradient(
    to right,
    rgba(223, 33, 27, 0) 50%,
    rgb(223, 33, 27) 0%,
    rgba(249, 210, 211, 0.4) 0%,
    rgba(249, 210, 211, 0.4) 0%
  );
}

.DayPicker-Day--startDate:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background: linear-gradient(
    to right,
    rgba(223, 33, 27, 0) 50%,
    rgb(223, 33, 27) 0%,
    rgba(249, 210, 211, 0.4) 0%,
    rgba(249, 210, 211, 0.4) 0%
  );
}

.DayPicker-Day--endDate {
  background: linear-gradient(
    to left,
    rgba(223, 33, 27, 0) 50%,
    rgb(223, 33, 27) 0%,
    rgba(249, 210, 211, 0.4) 0%,
    rgba(249, 210, 211, 0.4) 0%
  );
  border-radius: 0;
}

.DayPicker-Day--endDate:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background: linear-gradient(
    to left,
    rgba(223, 33, 27, 0) 50%,
    rgb(223, 33, 27) 0%,
    rgba(249, 210, 211, 0.4) 0%,
    rgba(249, 210, 211, 0.4) 0%
  );
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background-color: rgba(249, 210, 211, 0.4);
}

.DayPicker-Body {
  display: flex;
  flex-direction: column;
}

.DayPicker-Weekdays {
  display: flex;
  margin: 0 0 6px 0;
  width: 100%;
}

.DayPicker-Weekday {
  color: #5d6c74;
  justify-content: center;
  display: flex;
  flex: auto;
  text-transform: uppercase;
  padding: 0 0.5em;
  line-height: 22px;
}

.DayPicker-WeekdaysRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.DayPicker-Day {
  display: flex;
  flex: auto;
  padding: 0;
  height: 31px;
  cursor: pointer;
}

.DayPicker-Week {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  justify-content: center;
}

.DayPicker-Week .DayPicker-Day:first-of-type {
  flex-grow: 0.5;
}

.DayPicker-Week .DayPicker-Day:last-of-type {
  flex-grow: 0.5;
}

.DayPicker-Weekday:first-of-type {
  flex-grow: 0.5;
  justify-content: flex-start;
}

.DayPicker-Weekday:last-of-type {
  flex-grow: 0.5;
  justify-content: flex-end;
}

.DayPicker-Week .DayPicker-Day:first-of-type .date-item-container {
  margin-left: 0;
}

.DayPicker-Week .DayPicker-Day:last-of-type .date-item-container {
  margin-right: 0;
}

.DayPicker-Day--outside .date-item-container {
  color: rgb(217, 226, 231) !important;
}

.DayPicker-Day--today:not(.DayPicker-Day--disabled) {
  color: black;
  font-weight: normal;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--period)
  .date-item-container:hover {
  background: #dddddd !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--period):hover {
  background: white !important;
}

.DayPicker-Day:focus {
  outline: none !important;
}

.DayPicker-wrapper {
  position: absolute;
  background: white;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.input-date .icon-arrow-right {
  transform: rotate(0deg);
  width: 1.15em;
  height: 1.15em;
  position: relative;
  top: auto;
  right: auto;
  pointer-events: all;
}

.icon-arrow-right {
  transform: rotate(0deg);
  width: 2.2em;
  height: 1.2em;
}

.icon-arrow-right svg {
  stroke: #000;
}

.input-date .icon-arrow-left {
  transform: rotate(180deg);
  width: 1.15em;
  height: 1.15em;
  position: relative;
  top: auto;
  right: auto;
  pointer-events: all;
}

.icon-arrow-left {
  transform: rotate(180deg);
  width: 2.2em;
  height: 1.2em;
}

.icon-arrow-left svg {
  stroke: #000;
}

.DayPicker-wrapper {
  width: 460px;
  padding: 29px 29px 40px 29px;
}
.PowerOfAttorney {
  width: 68.4vw;
  height: 37vh;
  padding: 29px 29px 40px 29px;
}
.DayPicker-Month {
  margin: 0;
  width: 100%;
}

.date-item-container {
  width: 31px;
  height: 31px;
  margin: auto;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
}

.date-item-text {
  position: relative;
  top: 25%;
  text-align: center;
}

.lightpick__month-title {
  margin: 0;
}

.lightpick-months {
  font-size: 24px;
}

.notice-counter.nutrition {
  width: 22px;
  height: 22px;
  background: #98a3a7;
  font-size: 12px;
  top: -5px;
  left: 19px;
}

.miniVector {
  position: relative;
  align-items: center;
  text-align: center;
  top: -10px;
  cursor: pointer;
  padding-bottom: 10px;
}
