.amountEditTitle {
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.main-advance-wrap {
  display: flex;
  display: -ms-flexbox;
}

.main-advance-wrap .advance-cash-input {
  width: 70%;
}

.main-advance-wrap .advance-cash-input .description-wrap {
  margin-top: 10px;
}

.main-advance-wrap .advance-cash-input .description-wrap .description {
  white-space: break-spaces;
}

.main-advance-wrap .btn-calculate {
  width: auto;
  min-width: auto;
  height: 40px;
  position: relative;
  margin-left: 7px;
  text-transform: none;
  letter-spacing: 0;
  max-width: 200px;
}

@media (max-width: 768px) {
  .main-advance-wrap .advance-cash-input {
    width: unset;
  }
}
