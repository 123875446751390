.request-item-block.cost.advance {
  font-size: 20px;
  font-weight: 400;
}

.request-item-block.cost.advance .money {
  font-size: 20px;
  font-weight: 400;
}

.request-item-block.cost.amount {
  font-size: 20px;
  font-weight: 700;
}

.request-item-block.cost.amount .money {
  font-size: 20px;
  font-weight: 700;
}
