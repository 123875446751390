.sidebar-menu .sidebar-menu__item a.active,
.sidebar-menu .sidebar-menu__item > a.active path {
  color: #ff3b30;
  stroke: #ff3b30;
}

.sidebar-menu .sidebar-menu__item > a.active {
  font-weight: bold;
}

.icon-menu svg {
  stroke: red;
}
