.box-modal-form-block.custom-property {
  margin-top: -25px;
}

@media screen and (max-width: 600px) {
  .input-item--little {
    width: 40% !important;
  }
}

@media screen and (max-width: 600px) {
  .input-item--little {
    width: 40% !important;
  }
}
