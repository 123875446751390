/* Tooltip container */
.tooltip-expense {
  position: relative;
  display: inline-block;
}

.tooltip-expense.block {
  display: block;
}

/* Tooltip text */
.tooltip-expense .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  padding: 8px 12px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-expense .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-expense:hover .tooltiptext.hovered {
  visibility: visible;
  opacity: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-expense .tooltiptext.active {
  visibility: visible;
  opacity: 1;
}
