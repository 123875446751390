.content-wrap {
  background: #fff;
  padding: 25px 20px;
}

.content-wrap .input-item-row {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.content-wrap .input-item .input-label {
  min-width: 200px;
}

.content-wrap .input-item {
  width: 100%;
  margin-bottom: 20px;
  margin-right: 12px;
  min-width: 200px;
}

.content-wrap .input-item.half {
  width: 50%;
}

.content-wrap .input-item.quoter {
  width: 25%;
}

.content-wrap .input-item input {
  border: 1px solid #d9e2e7;
}

.break {
  display: none;

  height: 0;
}

.content-wrap_internal_report {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrap_internal_report_dates {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.internal_report_date {
  width: 49.7%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1280px) {
  .content-wrap .input-item-row {
    flex-wrap: wrap;
  }

  .content-wrap .input-item.half {
    width: 100%;
  }
  .break {
    display: inline;
    flex-basis: 100%;
  }
  .content-wrap .input-item.quoter {
    width: calc(50% - 12px);
    flex-direction: column;
  }
  .content-wrap_internal_report_dates {
    flex-direction: column;
  }

  .internal_report_date {
    width: 100%;
  }
}
