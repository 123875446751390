a {
  outline: none;
}

textarea {
  outline: none;
}

.miss-click-handler {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: none;
  height: 100%;
  width: 100%;
}

.box-modal {
  z-index: 1000;
}

.filter-dropdown-item .input-item input,
.filter-item .input-item input {
  margin-bottom: 0;
}

.filter-dropdown-item .input-item,
.filter-item .input-item {
  margin-bottom: 22px;
}

.input-choose {
  margin-top: 10px;
  margin-bottom: 0;
}

.profile-calc-table-th .icon,
.profile-calc-table-td .icon {
  margin-left: 5px;
  margin-right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.simplebar-offset {
  top: auto;
  height: 100%;
}

.simplebar-content {
  width: 100%;
}

.simplebar-scroll-content .simplebar-content {
  overflow: hidden !important;
}

.simplebar-content::-webkit-scrollbar {
  display: none;
}

body .simplebar-content {
  padding-top: 0;
}

.chat .chat-content {
  max-height: 750px;
  min-height: 0;
}

.chat-date:nth-of-type(1) {
  padding-top: 20px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:disabled {
  cursor: not-allowed;
}

.sidebar-menu .sidebar-menu__list-submenu li:last-of-type a {
  border-bottom: none;
}

.sidebar-menu .sidebar-menu__list-submenu li {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.sidebar-menu .active-submenu-list li {
  max-height: 48px;
  transition: all 0.5s ease-in-out;
}

.sidebar-menu .sidebar-menu__list-submenu li a {
  font-weight: 100;
}

.footer-center > :last-child {
  margin-bottom: 73px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 480px) {
  .request-expenses-title {
    align-items: center;
  }

  .arcticmodal-container_i {
    width: auto;
  }
}

@media (max-width: 768px) {
  .input-item.error .input-label {
    flex-direction: row;
    -ms-flex-direction: row;
  }
}

@media (max-width: 992px) {
  .request-list-item-status {
    margin-top: 61px;
  }
}

.sidebar-2 .filters-wrap .filter-dropdown-item-title {
  letter-spacing: 0.12em;
}

.input-item input {
  font-family: "HarmoniaSansProCyr";
}

.preview-modal {
  width: 100%;
  height: auto;
  position: relative;
}

.preview-image {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  padding-bottom: 27px;
  max-width: 80vw;
  min-width: 15vw;
  max-height: 75vh;
  min-height: 15vh;
}

.image-modal-content {
  position: relative;
  width: 100%;
  padding-left: 27px;
  padding-right: 27px;
}

@-moz-document url-prefix() {
  .preview-image {
    padding-bottom: 27px;
  }
}

.select {
  font-family: "HarmoniaSansProCyr" !important;
}

.request-files-dropzone:hover {
  background-color: rgba(229, 230, 234, 1) !important;
}
.box-modal-form-block .input-item.w-87 {
  width: 87px;
  min-width: 87px;
}

.arcticmodal-overlay.active {
  background-color: black;
  opacity: 0.5;
}

.sidebar-menu .sidebar-menu__link {
  padding-top: 13px;
  height: 40px;
  display: flex;
  align-items: center;
}

.sidebar-menu .sidebar-menu__link svg {
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.sidebar-2 .filters-wrap {
  padding-top: 2px;
  padding-bottom: 0;
}

.header .history-request-header .filters-title .icon {
  width: 20px;
  height: 20px;
}

.filters__info-nav a {
  font-weight: 700;
}

.breadcrumbs a {
  font-weight: 700;
}

@media (max-width: 1440px) {
  .request-list-item {
    padding-right: 13px;
    padding-left: 30px;
  }
}

@media (max-width: 768px) {
  .request-expenses-item-file {
    display: block;
  }
}

.request-files-item-dropdown {
  right: 0;
  left: auto;
}

.request-item-controls {
  position: relative;
}

@media (max-width: 768px) {
  .request-item-controls {
    position: absolute;
  }
}

.request-item-row {
  position: relative;
}

@media (max-width: 768px) {
  .request-item-row {
    position: static;
  }
}

#modal-print .modal-print-item {
  padding: 20px 0 20px 0;
}

#modal-print {
  width: 285px;
}

.history-request-item-bold {
  font-weight: bold;
}

.history-request .request-list-item-status i .icon {
  align-self: center;
}
.header-content .breadcrumbs-wrap .tx-right {
  align-self: flex-end;
}

.breadcrumbs-wrap {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 3%;
}

@media (max-width: 480px) {
  .header-content .breadcrumbs-wrap.breadcrumbs-sm-row {
    flex-direction: column;
  }
}

@media (max-width: 1365px) and (min-width: 480px) {
  .header-content .breadcrumbs-wrap.breadcrumbs-sm-row {
    flex-direction: row;
    display: flex;
    align-items: baseline;
    margin-left: 0;
  }
}

@media (max-width: 1365px) and (min-width: 480px) {
  .breadcrumbs-wrap {
    align-items: center;
  }
}

@media (max-width: 1365px) {
  .header-content .breadcrumbs-wrap .link-notice {
    display: inline-block;
  }
}

.detail-label {
  font-family: "HarmoniaSansProCyr";
  font-style: normal;
  font-weight: 600;
  font-size: 40px !important;
  line-height: 32px !important;
  color: #000000 !important;
}

@media (max-width: 1365px) {
  .detail-label {
    font-size: 32px !important;
  }
}

@media (max-width: 715px) {
  .detail-label {
    font-size: 28px !important;
  }
}

.main-profile.main {
  width: 100%;
}
.profile-form-input-item .input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #c4c4c4;
  height: 41px;
  font-size: 18px;
  padding: 0 7px 12px 7px;
  font-family: "HarmoniaSansProCyr";
}

.profile-setting-lang .radio {
  margin-right: 22px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .request-list-item-status {
    max-width: 234px;
  }
}

.request-item-content .input-choose {
  justify-content: flex-start;
}

.request-item-content .input-choose-item {
  margin: 0 8px 2px 0;
}

.chat-content-mess.orange .chat-content-mess-author {
  color: #4cd964;
}

.chat-content-mess.orange .chat-content-mess-text {
  background: #4cd964;
  color: #fff;
}

.add-trip {
  /* margin-left: 20px; */
}

.add-trip-label {
  flex-wrap: wrap;
}

.add-trip-label {
  /* -ms-flex-pack: start;
  justify-content: flex-start; */
}

@media (max-width: 1365px) {
  .history-request {
    display: block;
  }
}

.list-agreement-title .num span {
  margin-top: 1px;
}

.viewed-item {
  background-color: #f6f6f5;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.inHandWrapper {
  padding-top: 5px;
  margin-right: 15px;
}

.d-none {
  display: none !important;
}

.small-btn {
  width: 140px;
  height: 28px;
  border-radius: 4px;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}

.icon-eye {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.request-list-item-cost {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.conversion-rate {
  float: right;
  margin-right: 14px;
  text-align: right;
}

.text-gray {
  color: #5d6c74;
}

@media (max-width: 992px) {
  .conversion-rate {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .conversion-rate {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .conversion-rate {
    margin-right: 30px;
  }
}

.line-h-30 {
  line-height: 30px;
}

.x-s {
  font-size: 12px;
  width: 115px;
}
.m-t--20 {
  margin-top: -20px;
}

.modal-print-content--item {
  cursor: pointer;
  padding: 5px;
  position: relative;
}

.modal-print-content--item div {
  position: relative;
}

.modal-print-content--item::before {
  content: "";
  background-color: #eaeaea;
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.modal-print-content--item:hover::before {
  width: 100%;
  animation-name: hover;
  animation-duration: 0.3s;
}

@keyframes hover {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.request-expenses-bookkeeper .profile-calc-table-row > :first-child {
  max-width: 150px;
  min-width: 95px;
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(2) {
  max-width: 220px;
  min-width: 100px;
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(3) {
  max-width: 250px;
  width: 100%;
}
@media (max-width: 768px) {
  .request-expenses-bookkeeper .profile-calc-table-row > :nth-child(3) {
    max-width: 250px;
    min-width: 200px;
  }
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(4) {
  max-width: 450px;
  min-width: 250px;
  width: 100%;
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(5) {
  max-width: 150px;
  min-width: 200px;
  width: 100%;
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(6) {
  max-width: 150px;
  min-width: 87px;
  width: 100%;
}
.request-expenses-bookkeeper .profile-calc-table-row > :nth-child(7) {
  max-width: 150px;
  min-width: 80px;
  width: 100%;
}
.request-expenses-bookkeeper .profile-calc-table-row > .last {
  width: 38px;
  max-width: 38px;
  min-width: 38px;
}

@media (max-width: 1220px) {
  .request-item-controls {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .request-item-row {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .request-item-row.cost-row {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .request-item-block-costs .cost {
    margin-bottom: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) and (max-width: 1740px) {
  /* IE10 IE11 */
  .request-item-controls {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .request-item-row {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .request-item-row.cost-row {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .request-item-block-costs .cost {
    margin-bottom: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .input-item-row .DayPicker-wrapper {
    left: 0;
  }
}

@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) and (max-width: 1367px) {
  .request-bottom-controls .btn {
    max-width: 210px;
  }
}

@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) and (max-width: 1280px) {
  .request-bottom-controls .btn {
    max-width: 317px;
  }
}

@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) and (max-width: 1100px) {
  .request-bottom-controls .btn {
    max-width: 210px;
  }
}
@media (max-width: 1024px) {
  .request-item-block .DayPicker-wrapper {
    left: -60px;
    width: 400px;
  }
}
@media (max-width: 1050px) {
  .request-menu ul li {
    min-width: 205px;
  }
}
@media (max-width: 768px) {
  .request-menu ul li {
    width: 50%;
    min-width: auto;
  }
}

.znr-search-btn {
  width: 154px;
  height: 48px;
  font-size: 20px;
  text-transform: none;
}

.znr-search-row {
  align-items: center;
  justify-content: space-between;
}

#modal-print .modal-print-item {
  padding-top: 0;
  border-bottom: 1px solid #d9e2e7;
  margin-bottom: 20px;
}

#modal-print .modal-print-item:last-child {
  border-bottom: none;
}

/*#modal-print .modal-print-content img {
  margin-bottom: -4px;
}*/

/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #add-expenses .ieHelper .input-item {
    width: 37%!important;
  }
} */
#add-expenses .approved-amount-block > :nth-child(1) {
  width: 100%;
  max-width: 70px;
}
#add-expenses .approved-amount-block > :nth-child(2) {
  width: 100%;
  max-width: 235px;
}
#add-expenses .approved-amount-block > :nth-child(3) {
  width: 100%;
  max-width: 70px;
}
#add-expenses .approved-amount-block > :nth-child(4) {
  width: 100%;
  max-width: 235px;
}

.request-list-item-num.company-name {
  padding-right: 35%;
}

#add-expenses .input-item-row > :nth-child(1),
#add-expenses-2 .input-item-row > :nth-child(1) {
  max-width: 85px;
  width: 100%;
}
#add-expenses .input-item-row > :nth-child(2),
#add-expenses-2 .input-item-row > :nth-child(2) {
  max-width: 220px;
  width: 220px;
}
#add-expenses .input-item-row > :nth-child(3),
#add-expenses-2 .input-item-row > :nth-child(3) {
  max-width: 85px;
}
#add-expenses .input-item-row > :nth-child(4),
#add-expenses-2 .input-item-row > :nth-child(4) {
  max-width: 220px;
  width: 220px;
}
#add-expenses .input-item-row.line-tree > :nth-child(1) {
  max-width: 640px;
  width: 100%;
}
.w-180 {
  max-width: 180px !important;
}
.profile-calc-table-th,
.profile-calc-table-td {
  min-width: 105px;
}
.w-81 {
  width: 81px;
  max-width: 81px;
}

.access-denied-wrap {
  display: block;
  margin: 0 auto;
}

.access-denied-wrap .image-wrap {
  display: flex;
  justify-content: center;
}

.break-spaces {
  white-space: break-spaces;
}

/* IE10+ */
@media (max-width: 1440px) and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
  .request-list-item {
    padding-right: 32px;
  }
}

.znr-by-business-trip {
  width: 68% !important;
  margin-bottom: 0 !important;
}

.znr-by-cities {
  width: 33% !important;
  margin-bottom: 0 !important;
}

.znr-by-cities-mob {
  display: none;
}

@media (max-width: 768px) {
  .znr-by-business-trip {
    width: 100% !important;
    margin-right: auto !important;
  }
  .znr-by-cities {
    display: none;
  }
  .znr-by-cities-mob {
    display: block;
  }
  .znr-by-employee {
    margin-right: 0 !important;
  }
}

.cost {
  display: flex;
  flex-direction: column;
  align-items: start !important;
}

.cost span {
  line-height: 25px;
}

.request-header-wrapper {
  display: flex;
  margin-top: 27px;
}

.error-message-wrapper {
  font-size: 20px;
  font-weight: bold;
}

.create-expense-bootstrap-footer {
  justify-content: space-between;
}

@media (max-width: 991px) {
  .create-expense-bootstrap-footer {
    justify-content: space-around;
  }
}

.form-check .form-check-input {
  margin-top: 2px;
}

.request-header-right-wrapper {
  border-left: 1px dashed #98a2a8;
  min-width: 289px;
}

.request-header-right {
  margin-left: 32px;
  height: 100%;
}

@media (max-width: 768px) {
  .request-header-wrapper {
    flex-direction: column;
  }

  .request-header-right-wrapper {
    border-left: none;
    min-width: unset;
  }

  .request-header-right {
    margin-left: 0;
  }
}

.request-item-block .add-trip-label {
  display: block;
}

.HarmoniaSansProCyr {
  font-family: "HarmoniaSansProCyr", sans-serif;
}

.request-item-row .request-item-num .space-break {
  display: inline;
  flexgrow: 0;
}
.request .request-item .request-item-row {
  display: flex;
  justify-content: space-between;
}
.request-list-item-center {
  padding-top: 40px;
}
@media (max-width: 980px) {
  #expense-modal .DayPicker-wrapper {
    width: 100% !important;
    padding: 26px 41px 14px 46px !important;
    font-size: 14px;
    border-radius: 5px;
  }
}
