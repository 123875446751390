.calendar-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 12px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid "#cad5db";
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 8px;
  margin-bottom: 24px;
}

@media (min-width: 980pxx) {
  .calendar-input {
    margin-bottom: 3px;
    width: 100%;
  }
}
